export const ENGAGEMENT_STATUSES = {
  DRAFT: 'Draft',
  IN_PROGRESS: 'In progress',
  PROCESSING_RESULTS: 'Processing results',
  RAW_DATA_AVAILABLE: 'Raw data available',
  INSIGHTS_AVAILABLE: 'Insights available',
  ADMIN_ONLY: 'Admin only',
};

export type EngagementStatus = typeof ENGAGEMENT_STATUSES;
export type EngagementStatusKey = keyof typeof ENGAGEMENT_STATUSES;
export type EngagementStatusValues =
  (typeof ENGAGEMENT_STATUSES)[keyof typeof ENGAGEMENT_STATUSES];

export const ALL_ADBOARD_ENGAGEMENT_STATES = [
  'draft',
  'planning',
  'live',
  'closed',
] as const;
export type AdBoardEngagementStateType =
  (typeof ALL_ADBOARD_ENGAGEMENT_STATES)[number];

export const ALL_ENGAGEMENT_DISCRIMINATORS = [
  'SurveyEngagement',
  'AdBoardEngagement',
] as const;
export type EngagementDiscriminatorType =
  (typeof ALL_ENGAGEMENT_DISCRIMINATORS)[number];

// TODO use generated types
export interface EngagementDto {
  id: string;
  name: string;
  discriminator: EngagementDiscriminatorType;
  displayTitle?: string;
  resultsStatus: string;
  refreshStatus: string;
  startDate?: string;
  endDate?: string;
  completedDate?: string;
  responseCountTarget?: number;
  responseCount?: number;
  surveySparrowId?: string;
  hideDemographics: boolean;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
  themes?: string;
  objectives?: string;
  state?: AdBoardEngagementStateType;
  adBoardEngagementId?: string;
  executiveSummaryIntro?: string;
  executiveSummaryRecommendations?: string;
}

export interface UpdateEngagementDto {
  name?: string;
  displayTitle?: string;
  startDate?: Date;
  endDate?: Date;
  responseCountTarget?: number;
  responseCount?: number;
  completedDate?: Date;
  surveySparrowId?: string;
}

export interface CreateEngagementDto {
  name: string;
  displayTitle: string;
  resultsStatus: string;
  startDate?: Date;
  endDate?: Date;
  completedDate?: Date;
  responseCountTarget?: number;
  responseCount: number;
  surveySparrowId?: string;
  tenantId: string;
}

export interface RespondentDemographicDto {
  responseId: string;
  demographics: { [key: string]: string };
}

export interface BulkUpdateRespondentDemographicsDto {
  respondentsDemographics: RespondentDemographicDto[];
}
