import React, { useState } from 'react';
import { Container, Heading, Stack, Text } from '@chakra-ui/react';

import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomToast } from '../../components/CustomToast';
import GenericButton from '../../components/GenericButton';

export const HealthChecks: React.FunctionComponent = () => {
  // CEP/HCP connection check
  const [HCPApiResponse, setHCPApiResponse] = useState<string | undefined>(
    undefined
  );

  // Handle notification toast (error, success...)
  const { addToast } = CustomToast();

  const handleHCPCall = async () => {
    try {
      const response = await apiClient.healthApi.healthControllerGetHCPApi();

      if (response) {
        setHCPApiResponse(response.data);
      }
    } catch (error) {
      const toastMessage =
        `CEP/HCP connection check error:\n` +
        (error instanceof Error
          ? error.message
          : 'An unexpected error occurred. Please try again.');
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  };

  return (
    <Container maxW="xl">
      <Heading as="h1" fontSize={24} lineHeight={'32px'}>
        Admin page
      </Heading>
      <Heading as="h2" size={'xs'}>
        Used to monitor the CEP
      </Heading>
      {/* CEP/HCP connection check */}
      <Stack direction="row" spacing={2} alignItems={'center'}>
        <GenericButton
          type="button"
          text={`Check CEP/HCP apis connection`}
          onClick={handleHCPCall}
          margin={'16px 8px 8px 0'}
        />
        <Text>{HCPApiResponse ?? 'nope'}</Text>
      </Stack>
    </Container>
  );
};
