import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Heading, Stack } from '@chakra-ui/react';
import { ComparableEngagements, EngagementFile } from '@keyops-cep/api-client';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import Loading from '../../components/Loading';
import { useTenant } from '../../hooks/useTenants';
import { ROOT, TENANTED_ENGAGEMENTS_PAGE } from '../../utils/internal-routes';

import { ComparableEngagementsCard } from './components/comparableEngagementsCard';
import { EngagementActionButtons } from './components/engagementActionButtons';
import { EngagementFileCard } from './components/engagementFileListCard';
import { EngagementSettingsCard } from './components/engagementSettingsCard';
import { EngagementStatusCard } from './components/engagementStatusCard';
import { UpdateEngagementForm } from './components/updateEngagementForm';

type SurveyEngagementViewPropTypes = {
  tenantName: string;
  engagement: EngagementDto;
  isLoading: boolean;
  handleRefreshSurvey: () => void;
  fetchEngagementFiles: () => void;
  fetchData: () => void;
  comparableEngagements: ComparableEngagements[];
  handleListComparableEngagement: (engagementId: string) => Promise<void>;
  engagementFiles: EngagementFile[];
};

const SurveyEngagementView = ({
  tenantName,
  engagement,
  isLoading,
  handleRefreshSurvey,
  fetchEngagementFiles,
  fetchData,
  comparableEngagements,
  handleListComparableEngagement,
  engagementFiles,
}: SurveyEngagementViewPropTypes) => {
  const tenant = useTenant();
  const navigate = useNavigate();

  if (!tenant || !engagement || isLoading) {
    return <Loading />;
  }

  return (
    <Container maxWidth="1600px" width="100%" margin="auto">
      <CustomBreadcrumb
        list={[
          { text: 'Home', link: ROOT },
          {
            text: `${tenantName} Engagements`,
            link: TENANTED_ENGAGEMENTS_PAGE.replace(':tenantId', tenant.id),
          },
          ...(engagement ? [{ text: engagement.name, link: '' }] : []),
        ]}
      />
      <Stack
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <Heading
          as="h1"
          fontSize={24}
          lineHeight={'32px'}
          textAlign="left"
          margin={5}
        >
          {engagement?.name}
        </Heading>

        <EngagementActionButtons
          engagement={engagement}
          tenant={tenant}
          isLoading={isLoading}
          handleRefreshSurvey={handleRefreshSurvey}
          fetchEngagementFiles={fetchEngagementFiles}
          navigate={navigate}
        />
      </Stack>
      <Stack
        spacing={3}
        marginTop={8}
        marginRight={0}
        padding={3}
        justifyContent="space-between"
        direction="row"
      >
        <Stack spacing={6} direction="column">
          <UpdateEngagementForm
            engagement={engagement}
            isDisabled={isLoading}
            navigate={navigate}
          />
          <EngagementSettingsCard
            engagement={engagement}
            fetchEngagement={fetchData}
            isDisabled={isLoading}
          />
          {comparableEngagements && comparableEngagements?.length > 0 && (
            <ComparableEngagementsCard
              comparableEngagements={comparableEngagements}
              currentEngagement={engagement}
              handleListComparableEngagement={handleListComparableEngagement}
            />
          )}
        </Stack>
        <Stack spacing={6} direction="column">
          <EngagementStatusCard
            engagement={engagement}
            fetchEngagement={fetchData}
            isDisabled={isLoading}
          />
          <EngagementFileCard
            engagementFiles={engagementFiles}
            fetchEngagementFiles={fetchEngagementFiles}
          ></EngagementFileCard>
        </Stack>
      </Stack>
    </Container>
  );
};

export default SurveyEngagementView;
