import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react';

import { EngagementTypes } from '../../../utils/ad-boards';
import lang from '../../../utils/lang';

import { CreateAdBoardForm } from './createAdBoardForm';
import { CreateSurveyForm } from './createSurveyForm';

export function CreateEngagementModal({
  engagementType,
  isOpen,
  onClose,
}: {
  engagementType: EngagementTypes | undefined;
  isOpen: boolean;
  onClose: UseModalProps['onClose'];
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {engagementType === 'AdBoardEngagement'
            ? lang.engagement.single.add.action('ad board')
            : lang.engagement.single.add.action('survey')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {engagementType === 'AdBoardEngagement' ? (
            <CreateAdBoardForm onClose={onClose} />
          ) : (
            <CreateSurveyForm onClose={onClose} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
