import {
  AdBoardEngagement,
  UpdateAdBoardEngagementDto,
} from '@keyops-cep/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomToast } from '../../components/CustomToast';
import lang from '../../utils/lang';

// Define the mutation function with a single object argument for `useMutation` compatibility
const updateAdBoardEngagement = async ({
  adBoardEngagementId,
  updateValues,
}: {
  adBoardEngagementId: string;
  updateValues: UpdateAdBoardEngagementDto;
}): Promise<AdBoardEngagement> => {
  const result =
    await apiClient.adBoardEngagementsApi.adBoardEngagementControllerUpdate(
      updateValues,
      adBoardEngagementId
    );
  return result.data;
};

export const useUpdateAdBoardEngagement = () => {
  const { addToast } = CustomToast();
  const queryClient = useQueryClient();

  return useMutation<
    AdBoardEngagement, // Success response type
    unknown, // Error type
    {
      // Variable type (the argument for the mutation function)
      adBoardEngagementId: string;
      updateValues: UpdateAdBoardEngagementDto;
    }
  >({
    mutationFn: updateAdBoardEngagement, // The mutation function
    onSuccess: (updatedAdBoard) => {
      if (updatedAdBoard) {
        // Refetch all engagements
        queryClient.invalidateQueries({ queryKey: ['fetchAllEngagements'] });
        const toastMessage = `The ad board '${updatedAdBoard.displayTitle}' has been updated!`;
        addToast(toastMessage, 'success');
      }
    },
    onError: (error) => {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      addToast(toastMessage, 'error');
    },
  });
};
