import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Box, Heading, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';

import { getEngagementResponses } from '../../api/functions/engagements.functions';
import { getResponseRespondentDemographics } from '../../api/functions/response.functions';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import { CustomToast } from '../../components/CustomToast';
import FileUpload from '../../components/Form/FileUpload';
import Loading from '../../components/Loading';
import { useEngagement } from '../../hooks/useEngagement';
import { useTenant } from '../../hooks/useTenants';
import { handleDemographicsUpload } from '../../utils/functions/bulk-demographics';
import { dateFormatter } from '../../utils/functions/common-utils';
import {
  ROOT,
  TENANTED_ENGAGEMENT_SINGLE_PAGE,
  TENANTED_ENGAGEMENTS_PAGE,
} from '../../utils/internal-routes';
import lang from '../../utils/lang';

import { ResponsesTable } from './components/responsesTable';
import { UpdateRespondentDemographicsModal } from './components/updateRespondentDemographicsModal';

//Build table logic
export interface ResponseTableHeader extends ResponseTableData {
  actions: string;
}

interface ResponseTableData {
  id: string;
  completedAt?: Date;
  surveySparrowId: string;
  respondentDemographics: string[];
}

export interface RespondentDemographic {
  id: string;
  name: string;
  value: string;
}

export const buildResponsesTableColumns = (
  setIsOpen: (arg0: boolean) => void,
  setSelectedResponseId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const columnHelper = createColumnHelper<ResponseTableHeader>();
  const columns = [
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'Object ID',
    }),
    columnHelper.accessor('surveySparrowId', {
      cell: (info) => info.getValue(),
      header: 'Survey Sparrow ID',
    }),
    columnHelper.accessor('completedAt', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: 'Date Completed At',
    }),
    columnHelper.accessor('respondentDemographics', {
      cell: (info) => {
        return (
          <Box role="demographics">
            {info.getValue().map((item: string, index: number) => (
              <Box key={index} role="demographic">
                {item} <br />
              </Box>
            ))}
          </Box>
        );
      },
      header: 'Respondent Demographics',
    }),
    columnHelper.accessor('actions', {
      cell: (info) => (
        <Text
          display="flex"
          justifyContent="space-between"
          alignContent="space-between"
        >
          <FaEdit
            role="demographicsEdit"
            onClick={() => {
              setSelectedResponseId(info.row.original.id);
              setIsOpen(true);
            }}
          />
        </Text>
      ),
      header: 'Actions',
    }),
  ];

  return columns;
};

const buildResponseTableData = (responseTableData: ResponseTableData[]) => {
  const data: ResponseTableHeader[] = [];

  responseTableData.forEach((response) =>
    data.push({
      id: response.id,
      surveySparrowId: response.surveySparrowId,
      completedAt: response.completedAt
        ? new Date(response.completedAt)
        : undefined,
      actions: '<p>test<p>',
      respondentDemographics: response.respondentDemographics.sort(), // sort alphabetically for some consistency
    })
  );

  return data;
};

const Responses: React.FunctionComponent = () => {
  const tenant = useTenant();
  const { engagementId } = useParams();

  const { data: engagement } = useEngagement(engagementId);
  const [responseTableData, setResponseTableData] = useState(
    [] as ResponseTableData[]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [currentDemographics, setCurrentDemographics] = useState(
    {} as Record<string, RespondentDemographic[]>
  );
  const [selectedResponseId, setSelectedResponseId] = useState<string | null>(
    null
  );
  const [responsesUpdated, setResponsesUpdated] = useState(false);
  let data = buildResponseTableData(responseTableData);

  // Handle notification toast (error, success...)
  const { addToast } = CustomToast();

  const fetchResponses = useCallback(async () => {
    try {
      setIsLoading(true);
      const responses = await getEngagementResponses(engagement?.id);
      if (responses) {
        const responseTableData = await Promise.all(
          responses.data.map(async (responseObject) => {
            const respondentDemographics =
              await getResponseRespondentDemographics(responseObject.id);
            setCurrentDemographics((prevState) => ({
              ...prevState,
              [responseObject.id]: respondentDemographics
                ? respondentDemographics.data.map((respondentDemographic) => {
                    return {
                      id: respondentDemographic.id,
                      name: respondentDemographic.name,
                      value: respondentDemographic.value,
                    };
                  })
                : [],
            }));
            return {
              ...responseObject,
              respondentDemographics: respondentDemographics?.data
                ? respondentDemographics.data.map(
                    (respondentDemographic) =>
                      `${respondentDemographic.name}: ${respondentDemographic.value}`
                  )
                : [],
            };
          })
        );

        setResponseTableData(responseTableData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`fetchResponses` + error);
      const toastMessage =
        error instanceof Error
          ? error.message
          : 'An unexpected error occurred. Please try again.';
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
      setIsLoading(false);
    }
  }, [addToast, engagement]);

  useEffect(() => {
    if (engagement?.id || responsesUpdated) {
      fetchResponses();
    }
  }, [engagement, responsesUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    data = buildResponseTableData(responseTableData); // eslint-disable-line react-hooks/exhaustive-deps
  }, [engagement, responsesUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <CustomBreadcrumb
        list={[
          { text: 'Home', link: ROOT },
          {
            text: 'Engagements',
            link: TENANTED_ENGAGEMENTS_PAGE.replace(
              ':tenantId',
              tenant ? tenant.id : ''
            ),
          },
          ...(engagement
            ? [
                {
                  text: `${engagement?.name}`,
                  link: TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                    ':tenantId',
                    tenant ? tenant.id : ''
                  ).replace(':engagementId', engagement?.id),
                },
              ]
            : []),
          {
            text: 'Responses',
            link: '',
          },
        ]}
      />

      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        paddingLeft={5}
        paddingRight={5}
      >
        <Heading as="h1" fontSize={24} lineHeight={'32px'}>
          {isLoading
            ? lang.responses.all.title
            : lang.responses.all.title +
              (engagement ? ` for ` + engagement.name : ``)}
        </Heading>

        <Stack spacing={3} direction="row" justify="right">
          <FileUpload
            label={lang.responses.all.uploadDemographics}
            handleChange={(result) => {
              setIsLoading(true);
              handleDemographicsUpload(result, engagement?.id).then(() => {
                //assume this will clear loading state
                setResponsesUpdated(!responsesUpdated);
              });
            }}
          />
        </Stack>
      </Stack>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ResponsesTable
            data={data}
            setIsOpen={setIsOpen}
            setSelectedResponseId={setSelectedResponseId}
          />
          {isOpen && (
            <UpdateRespondentDemographicsModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              currentDemographics={
                currentDemographics[selectedResponseId || ''] ?? []
              }
              onSave={() => setResponsesUpdated(!responsesUpdated)}
            />
          )}
        </>
      )}
    </>
  );
};

export default Responses;
