import Papa from 'papaparse';

import {
  BulkUpdateRespondentDemographicsDto,
  RespondentDemographicDto,
} from '../../api/dto/engagement.dto';
import { bulkUpdateDemographicsCall } from '../../api/functions/engagements.functions';

interface ParsedRow {
  [key: string]: string;
}

const responseIdFieldName = 'responseId';

export const handleDemographicsUpload = async (
  content: string | ArrayBuffer | null,
  engagementId?: string
) => {
  if (!engagementId || !content) return;

  const csvString =
    typeof content === 'string'
      ? content
      : await new Blob([content], {
          type: 'text/plain; charset=utf-8',
        }).text();

  const parsedCsv = Papa.parse<ParsedRow>(csvString, {
    header: true,
    transformHeader: (header) => {
      if (header.toLowerCase() === 'submission id') {
        return responseIdFieldName;
      }
      return header;
    },
  });

  const respondentDemographicDtos = parsedCsv.data.map((row) => {
    const respondentDemographicsDto: RespondentDemographicDto = {
      responseId: row[responseIdFieldName],
      demographics: {},
    };
    Object.entries(row).forEach(([key, value]) => {
      if (key !== responseIdFieldName) {
        respondentDemographicsDto.demographics[key] = value;
      }
    });
    return respondentDemographicsDto;
  });

  const bulkUpdateRespondentDemographicsDto: BulkUpdateRespondentDemographicsDto =
    { respondentsDemographics: respondentDemographicDtos };

  await bulkUpdateDemographicsCall(
    engagementId,
    bulkUpdateRespondentDemographicsDto
  );
};
