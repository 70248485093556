// Auth Routes
export const ROOT = `/`;
export const LOGIN = `/login`;
export const LOGOUT = `/logout`;

// Main Pages Routes
export const ADMINS_PAGE = `/admins`;
export const USERS_PAGE = `/users`;

// Tenants
export const TENANTS_PAGE = `/tenants`;
export const TENANTED_ENGAGEMENTS_PAGE = `/tenants/:tenantId/engagements`;
export const TENANTED_ENGAGEMENT_SINGLE_PAGE = `/tenants/:tenantId/engagements/:engagementId`;
export const TENANTED_ENGAGEMENT_ANALYSIS_PAGE = `/tenants/:tenantId/engagements/:engagementId/analysis`;
export const TENANTED_ENGAGEMENT_RESPONSES_PAGE = `/tenants/:tenantId/engagements/:engagementId/responses`;

// Engagements
export const ENGAGEMENTS_PAGE = `/engagements`;
export const TENANTED_USERS_PAGE = `/tenants/:tenantId/users`;

// Comparison
export const ENGAGEMENTS_COMPARISON_PAGE =
  '/tenants/:tenantId/engagements/:engagementId/compare-to/:comparableEngagementId';

// Health check statuses page
export const HEALTHCHECK_PAGE = `/healthcheck`;
