import { AxiosResponse } from 'axios';

import { METHOD_TYPES } from '../api-constants';
import { performApiRequest } from '../api-request-builder';
import { AnalysisResponseDto } from '../dto/analysis.dto';
import {
  BulkUpdateRespondentDemographicsDto,
  CreateEngagementDto,
  EngagementDto,
  EngagementStatusValues,
  UpdateEngagementDto,
} from '../dto/engagement.dto';
import { QuestionResponseDto } from '../dto/question.dto';
import { ResponseResponseDto } from '../dto/response.dto';
import {
  BULK_UPDATE_DEMOGRAPHICS_ENDPOINT,
  GET_ENGAGEMENT_ANALYSIS,
  GET_ENGAGEMENT_QUESTIONS,
  GET_ENGAGEMENT_RESPONSES,
  POST_ENGAGEMENT_ENDPOINT,
  REFRESH_SURVEY_ENDPOINT,
  UPDATE_ENGAGEMENT_ENDPOINT,
  UPDATE_ENGAGEMENT_HIDE_DEMOGRAPHICS_ENDPOINT,
  UPDATE_ENGAGEMENT_STATUS_ENDPOINT,
} from '../routes/engagement.routes';

export const refreshSurveyCall = async (
  engagementId?: string
): Promise<AxiosResponse<EngagementDto, undefined> | undefined> => {
  let response;
  try {
    if (!engagementId) throw new Error(`No engagementId`);
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: REFRESH_SURVEY_ENDPOINT.replace(':engagementId', engagementId),
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createEngagementCall = async (engagement: CreateEngagementDto) => {
  let response;
  try {
    response = await performApiRequest<CreateEngagementDto>({
      method: METHOD_TYPES.POST,
      endpoint: POST_ENGAGEMENT_ENDPOINT,
      params: {},
      data: engagement,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateEngagementCall = async (
  engagementId: string,
  engagement: UpdateEngagementDto
): Promise<AxiosResponse<EngagementDto, undefined> | undefined> => {
  let response;
  try {
    response = await performApiRequest<UpdateEngagementDto>({
      method: METHOD_TYPES.POST,
      endpoint: UPDATE_ENGAGEMENT_ENDPOINT.replace(
        ':engagementId',
        engagementId
      ),
      params: {},
      data: engagement,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateEngagementStatusCall = async (
  status: EngagementStatusValues,
  engagementId: string
) => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: UPDATE_ENGAGEMENT_STATUS_ENDPOINT.replace(
        ':engagementId',
        engagementId
      ),
      params: {},
      data: { resultsStatus: status },
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateEngagementHideDemographics = async (
  hideDemographics: boolean,
  engagementId: string
) => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: UPDATE_ENGAGEMENT_HIDE_DEMOGRAPHICS_ENDPOINT.replace(
        ':engagementId',
        engagementId
      ),
      params: {},
      data: { hideDemographics },
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEnagagementAnalysis = async (
  engagementId: string
): Promise<AxiosResponse<AnalysisResponseDto, undefined> | undefined> => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_ENGAGEMENT_ANALYSIS.replace(':engagementId', engagementId),
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEngagementQuestions = async (
  engagementId: string
): Promise<AxiosResponse<QuestionResponseDto[]> | undefined> => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_ENGAGEMENT_QUESTIONS.replace(':engagementId', engagementId),
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEngagementResponses = async (
  engagementId?: string
): Promise<AxiosResponse<ResponseResponseDto[]> | undefined> => {
  let response;
  try {
    if (!engagementId) throw new Error(`No engagementId`);
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_ENGAGEMENT_RESPONSES.replace(':engagementId', engagementId),
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const bulkUpdateDemographicsCall = async (
  engagementId: string,
  bulkUpdateRespondentDemographicsDto: BulkUpdateRespondentDemographicsDto
): Promise<AxiosResponse<EngagementDto, undefined> | undefined> => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.PUT,
      endpoint: BULK_UPDATE_DEMOGRAPHICS_ENDPOINT.replace(
        ':engagementId',
        engagementId
      ),
      data: bulkUpdateRespondentDemographicsDto,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
