import React from 'react';
import { useParams } from 'react-router-dom';
import { Center, Text, UseModalProps } from '@chakra-ui/react';
import { CreateAdBoardEngagementDto } from '@keyops-cep/api-client';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

import { ENGAGEMENT_STATUSES } from '../../../api/dto/engagement.dto';
import { CustomSelectInput } from '../../../components/Form/CustomSelectInput';
import { CustomTextInput } from '../../../components/Form/CustomTextInput';
import GenericButton from '../../../components/GenericButton';
import { useCreateAdBoardEngagement } from '../../../hooks/useCreateAdBoardEngagement';
import { useEngagements } from '../../../hooks/useEngagements';
import lang from '../../../utils/lang';

// Define the form validation schema using Yup
const validationSchema = yup.object({
  displayTitle: yup.string().required('Display title is required'),
  hcpAdBoardId: yup
    .string()
    .required('The ad board id fromn the Physician app is required'),
});

export function CreateAdBoardForm({
  onClose,
}: {
  onClose: UseModalProps['onClose'];
}) {
  const { tenantId } = useParams();
  const { refetch } = useEngagements(tenantId);

  const initialValues: CreateAdBoardEngagementDto = {
    displayTitle: '',
    hcpAdBoardId: '',
    tenantId: tenantId ?? '',
    resultsStatus: ENGAGEMENT_STATUSES['ADMIN_ONLY'],
  };

  // Mutation to create the adBoard
  const { mutate: createAdBoard } = useCreateAdBoardEngagement();

  const onFormSubmit = async (adBoardToCreate: CreateAdBoardEngagementDto) => {
    if (!(adBoardToCreate.tenantId.length > 0)) {
      throw new Error('There is no tenant');
    }
    if (!(adBoardToCreate.hcpAdBoardId.length > 0)) {
      throw new Error('There is no HCP adboard id');
    }

    await createAdBoard(adBoardToCreate);
    await refetch();
    onClose();
  };

  const formik: FormikProps<CreateAdBoardEngagementDto> =
    useFormik<CreateAdBoardEngagementDto>({
      initialValues,
      validationSchema,
      onSubmit: onFormSubmit,
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* adboard displayTitle */}
      <CustomTextInput
        name="displayTitle"
        label="display title"
        placeholder="The title you want to display"
        helperMessage=""
        value={formik.values.displayTitle}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.displayTitle && formik.errors.displayTitle && (
        <Text as="sub" color="red">
          {formik.errors.displayTitle}
        </Text>
      )}
      {/* adboard id in HCP */}
      <CustomTextInput
        name="hcpAdBoardId"
        label="The id of the ad board in the Physician app"
        helperMessage=""
        value={formik.values.hcpAdBoardId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.hcpAdBoardId && formik.errors.hcpAdBoardId && (
        <Text as="sub" color="red">
          {formik.errors.hcpAdBoardId}
        </Text>
      )}

      {/* Engagement status */}
      <CustomSelectInput
        name="resultsStatus"
        label="status"
        value={formik.values.resultsStatus}
        options={ENGAGEMENT_STATUSES}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.resultsStatus && formik.errors.resultsStatus && (
        <Text as="sub" color="red">
          {formik.errors.resultsStatus}
        </Text>
      )}

      <Center>
        {' '}
        <GenericButton
          type="submit"
          text={lang.save}
          onClick={formik.handleSubmit}
        />
      </Center>
    </form>
  );
}
