import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
} from '@chakra-ui/react';

import {
  ENGAGEMENT_STATUSES,
  EngagementDto,
} from '../../../api/dto/engagement.dto';
import { updateEngagementStatusCall } from '../../../api/functions/engagements.functions';
import { CustomToast } from '../../../components/CustomToast';
import { CustomSelectInput } from '../../../components/Form/CustomSelectInput';
import GenericButton from '../../../components/GenericButton';
import lang from '../../../utils/lang';

type EngagementStatusCardProps = {
  engagement: EngagementDto;
  fetchEngagement: () => void;
  isDisabled: boolean;
};

export const EngagementStatusCard = ({
  engagement,
  fetchEngagement,
  isDisabled,
}: EngagementStatusCardProps) => {
  const [engagementStatus, setEngagementStatus] = useState(
    ENGAGEMENT_STATUSES.ADMIN_ONLY
  );

  useEffect(() => {
    setEngagementStatus(
      engagement.resultsStatus ?? ENGAGEMENT_STATUSES.ADMIN_ONLY
    );
  }, [engagement]);
  const { addToast } = CustomToast();

  // UpdateStatus action is separated from the global engagement update command
  const handleUpdateStatus = async () => {
    try {
      await updateEngagementStatusCall(engagementStatus, engagement.id);
      const toastMessage = `The status of '${engagement.name}' has been updated!`;
      const toastStatus = 'success';
      addToast(toastMessage, toastStatus);
      fetchEngagement();
    } catch (error) {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  };

  return (
    <Card
      variant="elevated"
      size="xs"
      maxH="4xs"
      height="-webkit-fit-content"
      width="xs"
      padding={3}
    >
      <CardHeader>
        <Heading as="h2" fontSize={20} lineHeight={'20px'} textAlign="left">
          Status
        </Heading>
      </CardHeader>

      <CardBody textAlign="center">
        <CustomSelectInput
          name="status"
          label=""
          value={engagementStatus}
          options={ENGAGEMENT_STATUSES}
          isMandatory={false}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setEngagementStatus(e.target.value);
          }}
          onBlur={() => undefined}
        />
      </CardBody>
      <CardFooter alignSelf="center">
        <GenericButton
          type="button"
          text={lang.engagement.single.status.update}
          onClick={handleUpdateStatus}
          isDisabled={isDisabled}
        />
      </CardFooter>
    </Card>
  );
};
