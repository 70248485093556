import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, FormLabel, Heading, Text } from '@chakra-ui/react';
import { UpdateAdBoardEngagementDto } from '@keyops-cep/api-client';
import { Field, FormikProps, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { EngagementDto } from '../../../api/dto/engagement.dto';
import { CustomMarkdownInput } from '../../../components/Form/CustomMarkdownInput';
import { CustomTextInput } from '../../../components/Form/CustomTextInput';
import { useTenant } from '../../../hooks/useTenants';
import { useUpdateAdBoardEngagement } from '../../../hooks/useUpdateAdBoardEngagement';
import { TENANTED_ENGAGEMENTS_PAGE } from '../../../utils/internal-routes';
import lang from '../../../utils/lang';

type UpdateAdBoardEngagementFormPropTypes = {
  engagement: EngagementDto;
  isDisabled: boolean;
};

// Define the form validation schema using Yup
const validationSchema = yup.object({
  displayTitle: yup.string(),
  executiveSummaryIntro: yup.string(),
  executiveSummaryRecommendations: yup.string(),
});

const UpdateAdBoardEngagementForm = ({
  engagement,
  isDisabled,
}: UpdateAdBoardEngagementFormPropTypes) => {
  const tenant = useTenant();
  const navigate = useNavigate();

  // Mutation to update the adBoard
  const { mutate: updateAdBoardEngagement } = useUpdateAdBoardEngagement();

  const onFormSubmit = async (values: UpdateAdBoardEngagementDto) => {
    await updateAdBoardEngagement({
      adBoardEngagementId: engagement.id,
      updateValues: values,
    });
    navigate(TENANTED_ENGAGEMENTS_PAGE.replace(':tenantId', tenant.id));
  };

  const initialValues: UpdateAdBoardEngagementDto = {
    displayTitle: engagement.displayTitle,
    executiveSummaryIntro: engagement.executiveSummaryIntro || '',
    executiveSummaryRecommendations:
      engagement.executiveSummaryRecommendations || '',
  };

  const formik: FormikProps<UpdateAdBoardEngagementDto> =
    useFormik<UpdateAdBoardEngagementDto>({
      initialValues,
      validationSchema,
      onSubmit: onFormSubmit,
      enableReinitialize: true,
    });

  return (
    <FormikProvider value={formik}>
      <Card align="left" variant="elevated" width="xl" padding={3}>
        <Heading
          as="h2"
          fontSize={20}
          lineHeight={'20px'}
          textAlign="left"
          margin={3}
        >
          {lang.engagement.single.details}
        </Heading>
        <form onSubmit={formik.handleSubmit}>
          {/* Engagement displayTitle */}
          <CustomTextInput
            name="displayTitle"
            label="Engagement Display Title"
            placeholder=""
            helperMessage=""
            value={formik.values.displayTitle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isMandatory={false}
          />
          {formik.touched.displayTitle && formik.errors.displayTitle && (
            <Text as="sub" color="red">
              {formik.errors.displayTitle}
            </Text>
          )}

          {/* Engagement executive summary intro */}
          <div style={{ padding: 12 }}>
            <FormLabel htmlFor={'executiveSummaryIntro'}>
              Executive summary intro
            </FormLabel>
            <Field
              as={CustomMarkdownInput}
              id={'executiveSummaryIntro'}
              {...formik.getFieldProps('executiveSummaryIntro')}
            />
          </div>
          {formik.touched.executiveSummaryIntro &&
            formik.errors.executiveSummaryIntro && (
              <Text as="sub" color="red">
                {formik.errors.executiveSummaryIntro}
              </Text>
            )}

          {/* Engagement executive summary recommendations */}
          <div style={{ padding: 12 }}>
            <FormLabel htmlFor={'executiveSummaryRecommendations'}>
              Executive summary recommendations
            </FormLabel>
            <Field
              as={CustomMarkdownInput}
              id={'executiveSummaryRecommendations'}
              {...formik.getFieldProps('executiveSummaryRecommendations')}
            />
          </div>
          {formik.touched.executiveSummaryRecommendations &&
            formik.errors.executiveSummaryRecommendations && (
              <Text as="sub" color="red">
                {formik.errors.executiveSummaryRecommendations}
              </Text>
            )}

          <Button
            type="submit"
            size={'sm'}
            bgColor="keyops.blue"
            color={'white'}
            isDisabled={isDisabled}
            m={3}
          >
            Save
          </Button>
        </form>
      </Card>
    </FormikProvider>
  );
};

export default UpdateAdBoardEngagementForm;
