import React, { Fragment, useState } from 'react';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import {
  ComparableEngagements,
  SurveyEngagement,
} from '@keyops-cep/api-client';

import { EngagementDto } from '../../../api/dto/engagement.dto';
import { CustomToast } from '../../../components/CustomToast';
import { GenericLink } from '../../../components/GenericLink';
import Loading from '../../../components/Loading';
import { CUSTOMER_UI_ENGAGEMENT_DETAILS } from '../../../utils/external-routes';
import { runAIComparisons } from '../../../utils/functions/compare.utils';
import { navigateToCustomerUi } from '../../../utils/functions/navigate-to-customer-ui';
import {
  ENGAGEMENTS_COMPARISON_PAGE,
  TENANTED_ENGAGEMENT_SINGLE_PAGE,
} from '../../../utils/internal-routes';
import lang from '../../../utils/lang';

type EngagementSettingsCardProps = {
  comparableEngagements: ComparableEngagements[];
  currentEngagement: EngagementDto;
  handleListComparableEngagement: (engagementId: string) => Promise<void>;
};

export const ComparableEngagementsCard = ({
  comparableEngagements,
  currentEngagement,
  handleListComparableEngagement,
}: EngagementSettingsCardProps) => {
  // Disable the link after clicking on it
  const [
    disableGenerateComparisonsLinkStates,
    setDisableGenerateComparisonsLinkStates,
  ] = useState<{
    [key: string]: boolean;
  }>({});
  const { addToast } = CustomToast();

  const handleGenerateComparisons = async (comparableEngagementsId: string) => {
    try {
      setDisableGenerateComparisonsLinkStates((prevStates) => ({
        ...prevStates,
        [comparableEngagementsId]: true,
      }));
      const result = await runAIComparisons(comparableEngagementsId);
      if (result.status >= 200) {
        addToast(
          lang.engagement.single.comparisonAnalysis.successfulComparisons,
          `success`
        );
        setDisableGenerateComparisonsLinkStates((prevStates) => ({
          ...prevStates,
          [comparableEngagementsId]: false,
        }));
        handleListComparableEngagement(currentEngagement.id);
      }
    } catch (error: Error | unknown) {
      setDisableGenerateComparisonsLinkStates((prevStates) => ({
        ...prevStates,
        [comparableEngagementsId]: false,
      }));
      addToast((error as Error)?.message, `error`, (error as Error)?.name);
    }
  };

  return (
    <Card variant="elevated" padding={3}>
      <CardHeader>
        <Heading as="h2" fontSize={20} lineHeight={'20px'} textAlign="left">
          {lang.engagement.single.comparableEngagement.title}
        </Heading>
        <Box pt={3}>
          <Text>
            {lang.engagement.single.comparableEngagement.current.themes}
          </Text>
          <Text pt={1}>{currentEngagement?.themes}</Text>
        </Box>
      </CardHeader>

      <CardBody justifyContent="start" padding={3} pt={2} pl={5}>
        <Table variant="unstyled">
          <Tbody>
            {comparableEngagements.map(
              (comparableEngagement: ComparableEngagements) => {
                // NB: disable the link to Comparison page when no comparable questions and no global report on ComparableEngagements
                const hasNoComparableQuestions =
                  comparableEngagement?.comparableQuestions === undefined ||
                  comparableEngagement?.comparableQuestions?.length === 0;
                const hasNoComparisonReport = !comparableEngagement?.report;

                return comparableEngagement.engagements.map(
                  (engagement: SurveyEngagement, key: number) => {
                    if (engagement.id !== currentEngagement.id) {
                      return (
                        <Tr key={key}>
                          <Td>
                            <GenericLink
                              text={engagement.name}
                              href={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                                ':tenantId',
                                engagement.tenantId
                              ).replace(':engagementId', engagement.id)}
                            />
                          </Td>
                          <Td maxW={'150px'}>{engagement?.displayTitle}</Td>
                          <Td maxW={'150px'}>
                            {lang.engagement.single.comparableEngagement.themes}
                            : {engagement?.themes}
                          </Td>
                          <Td fontSize={'12px'}>
                            {/* Link to preview engagement */}
                            <Box display={'block'}>
                              <GenericLink
                                text={lang.engagement.single.preview}
                                onClick={() => {
                                  navigateToCustomerUi(
                                    CUSTOMER_UI_ENGAGEMENT_DETAILS.replace(
                                      ':engagementId',
                                      engagement.id
                                    )
                                  );
                                }}
                              />
                            </Box>
                            {/* Display link to run comparisons if no previous comparable content. Display comparison analysis link otherwise */}
                            <Box display={'block'}>
                              {hasNoComparisonReport &&
                              hasNoComparableQuestions ? (
                                <Stack
                                  direction={'row'}
                                  alignContent={'center'}
                                >
                                  <GenericLink
                                    disable={
                                      disableGenerateComparisonsLinkStates[
                                        comparableEngagement.id
                                      ]
                                    }
                                    text={
                                      lang.engagement.single.comparisonAnalysis
                                        .generateComparisons
                                    }
                                    onClick={() =>
                                      handleGenerateComparisons(
                                        comparableEngagement.id
                                      )
                                    }
                                  />
                                  {disableGenerateComparisonsLinkStates[
                                    comparableEngagement.id
                                  ] && <Loading h={'20px'} size={'20px'} />}
                                </Stack>
                              ) : (
                                <GenericLink
                                  text={
                                    lang.engagement.single.comparisonAnalysis
                                      .linkTitle
                                  }
                                  href={ENGAGEMENTS_COMPARISON_PAGE.replace(
                                    ':tenantId',
                                    currentEngagement.tenantId
                                  )
                                    .replace(
                                      ':engagementId',
                                      currentEngagement.id
                                    )
                                    .replace(
                                      ':comparableEngagementId',
                                      engagement.id
                                    )}
                                />
                              )}
                            </Box>
                          </Td>
                        </Tr>
                      );
                    }
                    return <Fragment key={key}></Fragment>;
                  }
                );
              }
            )}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
