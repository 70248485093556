import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { apiClient } from '../../api/swagger-codegen-api-config';

/**
 * Hook that retrieve any type of engagement with the given id
 * @param engagementId
 * @returns {UseQueryResult<EngagementDto, Error>} - The found engagement or an error
 */
export const useEngagement = (
  engagementId?: string
): UseQueryResult<EngagementDto, Error> => {
  async function fetchEngagementById(): Promise<EngagementDto> {
    if (!engagementId) {
      throw new Error(`No engagement id in URL params`);
    }
    const response = await apiClient.engagementsApi.engagementControllerFindOne(
      engagementId
    );
    if (!response?.data) {
      throw new Error(`Engagement not found for id ${engagementId}`);
    }
    return response?.data;
  }
  return useQuery({
    queryKey: ['fetchEngagementById', engagementId],
    queryFn: fetchEngagementById,
    enabled: !!engagementId, // Disable query until engagementId is available
  });
};
