import {
  AdBoardEngagement,
  CreateAdBoardEngagementDto,
} from '@keyops-cep/api-client';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomToast } from '../../components/CustomToast';
import lang from '../../utils/lang';

// Define the mutation function separately
const createAdBoardEngagement = async (
  adBoardToCreate: CreateAdBoardEngagementDto
): Promise<AdBoardEngagement> => {
  const result =
    await apiClient.adBoardEngagementsApi.adBoardEngagementControllerCreate(
      adBoardToCreate
    );
  return result.data;
};

export const useCreateAdBoardEngagement = () => {
  const { addToast } = CustomToast();
  // NB: do not import it from App, as it triggers jest error
  const queryClient = new QueryClient();

  return useMutation<
    CreateAdBoardEngagementDto,
    unknown,
    CreateAdBoardEngagementDto,
    unknown
  >({
    mutationFn: createAdBoardEngagement,
    onSuccess: (createdAdBoard: CreateAdBoardEngagementDto) => {
      if (createdAdBoard) {
        // Refetch all engagements
        queryClient.invalidateQueries({
          queryKey: ['fetchAllEngagements'],
        });
        const toastMessage = `The ad board '${createdAdBoard.displayTitle}' has been created!`;
        const toastStatus = 'success';
        addToast(toastMessage, toastStatus);
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    },
  });
};
