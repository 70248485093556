import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { apiClient } from '../../api/swagger-codegen-api-config';

export const useEngagements = (
  tenantId?: string
): UseQueryResult<EngagementDto[], Error> => {
  const fetchAllEngagements = async (): Promise<
    EngagementDto[] | undefined
  > => {
    if (!tenantId) {
      throw new Error(`No tenantId`);
    }
    // TODO: create an endpoint get all enggts by tenantId
    const response =
      await apiClient.engagementsApi.engagementControllerFindAll();

    return response?.data?.filter((engagement: EngagementDto) =>
      tenantId ? engagement.tenantId === tenantId : true
    );
  };

  return useQuery({
    queryKey: ['fetchAllEngagements', tenantId],
    queryFn: fetchAllEngagements,
    enabled: !!tenantId, // Disable query until tenantId is available
  });
};
