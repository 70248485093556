import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ComparableEngagements } from '@keyops-cep/api-client';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { refreshSurveyCall } from '../../api/functions/engagements.functions';
import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomToast } from '../../components/CustomToast';
import Loading from '../../components/Loading';
import { useEngagement } from '../../hooks/useEngagement';
import useEngagementFiles from '../../hooks/useEngagementFiles';
import { useTenant } from '../../hooks/useTenants';
import lang from '../../utils/lang';

import AdBoardEngagementView from './AdBoardEngagementView';
import SurveyEngagementView from './SurveyEngagementView';

const Engagement: React.FunctionComponent = () => {
  const tenant = useTenant();
  const { engagementId } = useParams();

  const {
    data: engagement,
    refetch: fetchData,
    error,
  } = useEngagement(engagementId);

  const { engagementFiles, fetchEngagementFiles } =
    useEngagementFiles(engagementId);
  const [hasClickedRefreshButton, setHasClickedRefreshButton] = useState(false);
  const [hasStartedInterval, setHasStartedInterval] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const refreshStatusRef = useRef(engagement?.refreshStatus);
  const { addToast } = CustomToast();

  useEffect(() => {
    refreshStatusRef.current = engagement?.refreshStatus;
  }, [engagement?.refreshStatus]);

  useEffect(() => {
    const fetchDataWithInterval = async () => {
      const interval = setInterval(async () => {
        await fetchData();
        if (refreshStatusRef.current !== 'IN_PROGRESS') {
          clearInterval(interval);
          setLoading(false);
          setHasClickedRefreshButton(false);
          setHasStartedInterval(false);

          if (refreshStatusRef.current === 'COMPLETED') {
            // List again the comparable engagements as the refresh-survey just finished and may have updated the list
            handleListComparableEngagement(engagement?.id);

            addToast(lang.engagement.single.survey.refresh.success, 'success');
          } else {
            addToast(lang.engagement.single.survey.refresh.failure, 'error');
          }
        }
      }, 2000);

      return () => clearInterval(interval);
    };

    if (hasClickedRefreshButton && !hasStartedInterval) {
      setHasStartedInterval(true);
      fetchDataWithInterval();
    }
    // eslint-disable-next-line
  }, [addToast, fetchData, hasClickedRefreshButton, hasStartedInterval]);

  const handleRefreshSurvey = async () => {
    setLoading(true);

    await fetchData();
    if (engagement?.refreshStatus === 'IN_PROGRESS') {
      addToast(lang.engagement.single.survey.refresh.inProgress, 'error');
      setLoading(false);
      return;
    }

    await refreshSurveyCall(engagement?.id);
    await fetchData();
    setHasClickedRefreshButton(true);
  };

  const handleRefreshAdBoard = async () => {
    setLoading(true);

    await fetchData();

    if (engagement?.id) {
      try {
        await apiClient.adBoardEngagementsApi.adBoardEngagementControllerRefreshAdBoardData(
          engagement?.id
        );
        addToast(
          'Successfully refreshed AdBoard. Any survey results may take some time to update.',
          'success'
        );
      } catch (e) {
        console.log(e);
        addToast('Failed to refresh AdBoard', 'error');
        setLoading(false);
      }
      await fetchData();
    }

    setLoading(false);
  };

  const [comparableEngagements, setComparableEngagements] = useState<
    ComparableEngagements[]
  >([]);

  const handleListComparableEngagement = async (engagementId?: string) => {
    try {
      if (!engagementId) {
        throw new Error(`No engagementId`);
      }
      const result =
        await apiClient.comparableEngagementsApi.comparableEngagementsControllerGetAll(
          engagementId
        );
      if (result && result.data.length > 0) {
        const comparableEngagements = result.data;

        setComparableEngagements(comparableEngagements);
      }
    } catch (error) {
      addToast((error as Error)?.message, `warning`, 'Warning');
    }
  };

  useEffect(() => {
    if (engagement?.id && refreshStatusRef.current === 'COMPLETED')
      handleListComparableEngagement(engagement.id);
    // eslint-disable-next-line
  }, [engagement?.id]);

  const tenantName = tenant?.name
    ? tenant?.name.slice(0, 1)[0].toLocaleUpperCase() +
      tenant?.name.slice(1, tenant?.name.length)
    : '';

  const getRenderedView = (engagement: EngagementDto) => {
    if (engagement.discriminator === 'AdBoardEngagement')
      return (
        <AdBoardEngagementView
          tenantName={tenantName}
          engagement={engagement}
          fetchData={fetchData}
          isLoading={isLoading}
          handleRefreshAdBoard={handleRefreshAdBoard}
        />
      );
    else
      return (
        <SurveyEngagementView
          tenantName={tenantName}
          engagement={engagement}
          isLoading={isLoading}
          handleRefreshSurvey={handleRefreshSurvey}
          fetchEngagementFiles={fetchEngagementFiles}
          fetchData={fetchData}
          comparableEngagements={comparableEngagements}
          handleListComparableEngagement={handleListComparableEngagement}
          engagementFiles={engagementFiles}
        />
      );
  };

  if (error) {
    const toastMessage =
      error instanceof Error ? error.message : lang.errors.default;
    const toastStatus = 'error';
    addToast(toastMessage, toastStatus);
  }

  if (!tenant || !engagement || isLoading) {
    return <Loading />;
  }

  return getRenderedView(engagement);
};

export default Engagement;
