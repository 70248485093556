import { useEffect, useState } from 'react';
import { EngagementFile } from '@keyops-cep/api-client';

import { apiClient } from '../../api/swagger-codegen-api-config';

const useEngagementFiles = (engagementId?: string) => {
  if (!engagementId) {
    throw new Error(`No engagementId`);
  }
  const [engagementFiles, setEngagementFiles] = useState<EngagementFile[]>([]);
  const [isLoading, setLoading] = useState(false);

  const fetchEngagementFiles = async () => {
    setLoading(true);
    try {
      const response =
        await apiClient.engagementsApi.engagementControllerGetEngagementFiles(
          engagementId
        );
      setEngagementFiles(response.data);
    } catch (error) {
      console.error('Error fetching engagement files:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (engagementId) {
      fetchEngagementFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementId]);

  return {
    engagementFiles,
    fetchEngagementFiles,
    isLoading,
  };
};

export default useEngagementFiles;
